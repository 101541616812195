@import '~antd/dist/antd.less';


@border-color-base: #439D5B; // Customize border color
@input-hover-border-color: #439D5B; // Customize hover border color for inputs
@input-focus-border-color: #439D5B; // Customize focus border color for inputs
@btn-primary-bg: #439D5B; // Customize background color for primary buttons
@btn-primary-border: #439D5B; // Customize border color for primary buttons

@checkbox-color: #439D5B; // Customize checkbox color



// .ant-input:focus, .ant-input-focused, .ant-input:active {
//   border-color: @input-focus-border-color !important;
// }

.App {
  text-align: center;
  background-color: #61dafb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {



  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@primary-color: #e23235;