body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: white;
  height: 120px;
}

.wrapper {
  padding: 20px;
  max-width: 1080px;
  margin: 0 auto;
}

.navigation {
  display: flex;
  align-items: center;
  position: fixed;
}

.links {
  display: flex;
}

.link {
  margin: 0 10px;
}

.link>a {
  color: #242635;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  line-height: 22.4px;
  text-decoration: none;
}

.loginBtn {
  text-align: center;
  padding: 5px;
}

.button {
  text-align: center;
  background-color: gray;
}

.addCustomerForm {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
}

.h2 {
  text-align: center;
}

.settingsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 500px;
}

.sliderContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
  gap: 20px;
  overflow: hidden;
}

.datePicker {
  width: 100%;
}

.logOut {
  position: absolute;
  z-index: 200;
  top: 0;
  right: 0;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.switchBefore {
  background-color: #61dafb;
}

.settingsCard {
  display: flex;
  justify-content: center;
}

.settingsText {}

.componentSwitcher {
  margin: auto;
  padding-top: 20px;
  min-height: 100%;
}

.autoComplete {
  width: 100%;
  text-align: left;
}

/*.ant-select-selection-placeholder {*/
/*    text-align: left;*/
/*    margin-top: 10px*/
/*}*/

.textBox {
  width: 100%;
}

.borderClassWrapper {
  display: flex;
  justify-content: space-around;
  grid-auto-flow: column;
  grid-gap: 20px;
}

.container {
  max-width: 960px;
  margin: auto;
  min-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.sidenav {
  text-align: center;
  display: grid;
  grid-auto-flow: column;
  margin: auto;
  width: 100%;
  gap: 20px;
}

.container-dashboard {
  max-width: 90%;
  min-height: 100vh;

  text-align: center;
  margin: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #cc0909;
}

@media (max-width: 1400px) {
  .container-dashboard {
    max-width: 100%;
  }
}

.borderClassCSV {
  display: grid;
  flex-direction: column;
  min-width: 200px;
  /*lägg till padding*/
}

.buttonContainer {
  margin: auto;
  text-align: center;
}

.imageLogo {
  width: 140px;
  max-height: 140px;
  margin: 0 0 50px;

}

.blackButton {
  width: 100%;
  background-color: #323232 !important;
  border-color: #323232 !important;
}

.reportContainer {
  display: grid;
  flex-direction: column;
  width: 100%;
}

.reportHeaderPadding {
  padding-top: 24px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #439D5B !important;
}

.ant-picker:hover, .ant-picker-focused {
  border-color: #439D5B !important;
}

.ant-picker-active-bar {
  background: #439D5B !important;
}

.ant-input-affix-wrapper {
  border-color: #439D5B !important;
}

.ant-input {
  border-color: #439D5B !important;
}

.ant-select-selection-item::after{
  width: 500px !important;
  max-width: 500px !important;
}

.ant-select-dropdown {
  width: 300px !important; 
}
